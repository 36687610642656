#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.7em;
  margin-left: -2.7em;
  width: 5.4em;
  height: 5.4em;
  background-color: transparent;
}

#hill {
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  top: 1.7em;
  left: 1.7em;
  background-color: transparent;
  border-left: 0.25em solid #404456;
  transform: rotate(45deg);
}

#hill:after {
  content: '';
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  left: 0;
  background-color: transparent;
}

#box {
  position: absolute;
  left: 0;
  bottom: -0.1em;
  width: 1.5em;
  height: 1.5em;

  background-color: #404456;
  border: 0.25em solid #404456;
  border-radius: 15%;
  transform: translate(0, -1em) rotate(-45deg);
  animation: push 5s cubic-bezier(0.79, 0, 0.47, 0.97) infinite;
}

@keyframes push {
  0% {
    transform: translate(0, -1em) rotate(-45deg);
  }
  5% {
    transform: translate(0, -1em) rotate(-50deg);
  }
  20% {
    transform: translate(1em, -2em) rotate(47deg);
  }
  25% {
    transform: translate(1em, -2em) rotate(45deg);
  }
  30% {
    transform: translate(1em, -2em) rotate(40deg);
  }
  45% {
    transform: translate(2em, -3em) rotate(137deg);
  }
  50% {
    transform: translate(2em, -3em) rotate(135deg);
  }
  55% {
    transform: translate(2em, -3em) rotate(130deg);
  }
  70% {
    transform: translate(3em, -4em) rotate(217deg);
  }
  75% {
    transform: translate(3em, -4em) rotate(220deg);
  }
  100% {
    transform: translate(0, -1em) rotate(-225deg);
  }
}
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: absolute;

  top: 65%;
  left: 50%;
  margin-top: -2.7em;
  margin-left: -2.7em;
  overflow: hidden;
  width: 10em;
}
.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 5s infinite;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
  }
  15% {
    top: 0;
  }
  30% {
    top: -1.2em;
  }
  45% {
    top: -1.2em;
  }
  60% {
    top: -2.4em;
  }
  75% {
    top: -2.4em;
  }
}
.loading {
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: absolute;

  top: 60%;
  left: 50%;
  margin-left: -1.7em;

  text-align: center;
  span {
    display: inline-block;
  }
}

/* code #1 */
.loading01 {
  span {
    animation: loading01 1.4s infinite alternate;
    @for $i from 0 through 6 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}
@media (max-width: 768px) {
  #box {
    width: 1em;
    height: 1em;
  }
  #hill {
    width: 5.6em;
    height: 5.6em;
    top: 1.7em;
    left: 1.7em;
    background-color: transparent;
    border-left: 0.25em solid #404456;
    transform: rotate(45deg);
  }
  .loading {
    top: 56%;
    span {
      font-size: 22px;
    }
  }
}
@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
