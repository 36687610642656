.NoDataEnvContainer {
  position: relative;
  width: 100%;
}

.EnvImage {
  display: block;
  width: 100%;
  height: auto;
  padding: 32px 0px;
}

.EnvHoverImage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
  height: 0;
  border-radius: 32px;

  /* transition: 0.5s ease; */
  transition: height 0.3s ease;
}

.NoDataEnvContainer:hover .EnvHoverImage {
  height: 100%;
}

.EnvAddButtpn {
  position: absolute;

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
