body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6b6b6b;
  background-color: #f8f9fe;
}
* {
  box-sizing: border-box;
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
img {
  max-width: 100%;
}
code {
  font-family: 'Poppins';
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Bold.eot');
  src: url('../public/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Bold.woff2') format('woff2'),
    url('../public/fonts/Poppins-Bold.woff') format('woff'),
    url('../public/fonts/Poppins-Bold.ttf') format('truetype'),
    url('../public/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Medium.eot');
  src: url('../public/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Medium.woff2') format('woff2'),
    url('../public/fonts/Poppins-Medium.woff') format('woff'),
    url('../public/fonts/Poppins-Medium.ttf') format('truetype'),
    url('../public/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-SemiBold.eot');
  src: url('../public/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../public/fonts/Poppins-SemiBold.woff') format('woff'),
    url('../public/fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('../public/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Regular.eot');
  src: url('../public/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Regular.woff2') format('woff2'),
    url('../public/fonts/Poppins-Regular.woff') format('woff'),
    url('../public/fonts/Poppins-Regular.ttf') format('truetype'),
    url('../public/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.atlwdg-popup {
  max-width: 500px !important;
}

#atlwdg-trigger {
  opacity: 0;
}
